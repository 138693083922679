import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../style/custom/header.scss"

const Header = (props) => {
  const {endComponent} = props;

  return (
    <header className="custom-header">
      <Navbar>
        <Nav className="main-navs">
          <a href="https://www.scape.com.au/" target="_blank">
            <Navbar.Brand>
              <img 
                src="https://cdn.vostro.app/cdn/hotspots/scape/Scape_Main logo.png"
                alt="logo" 
                className="logo" 
              />
            </Navbar.Brand>
          </a>
        </Nav>
        {endComponent && <Nav className="custom-header-end-elements">
          {endComponent}
        </Nav>}
      </Navbar>
    </header>
  );
}

Header.propTypes = {
  endComponent: PropTypes.node // additional component that will be placed on the header end part
}

export default Header;

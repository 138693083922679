import React from "react"
import Seo from "../components/section/seo";
import Main from "../components/main";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import RecoverPassword from "../components/portal/recover-password"; 
import Header from "../components/section/header";


const RecoverPasswordPage = () => {
    const dateNow = new Date();
    const year = dateNow && dateNow.getFullYear();

    return (
        <Main>
            <Seo title="Scape - Internet Portal">
                <meta name="description" content="Scape Internet" />
            </Seo>
            <Container 
                fluid
                className="recover-password-container vh-100"
            >
                <Header 
                    endComponent={
                        <Link className="login-link" to="/">
                            <i
                                className="fas fa-solid fa-arrow-left"
                            />
                            <span className="pl-2">
                                {"BACK TO LOGIN"}
                            </span>
                        </Link>
                    }
                />
                <Row className="d-flex flex-row recover-password-section"> 
                    <Col className="recover-password-form align-items-center" xs={12} sm={12} md={12} lg={4} xl={3}>
                        <section className="rp-form-logo-og">
                            <a href="https://www.scape.com.au/" target="_blank">
                                <img
                                    src="https://cdn.vostro.app/cdn/hotspots/scape/Scape_Main%20logo.svg"
                                    alt="logo"
                                    className="form-logo"
                                />
                            </a>
                        </section>
                        <RecoverPassword />
                        <section className="rec-pass-footer">  
                            {`© Scape ${year}. All rights reserved.`}
                        </section>
                    </Col>
                    <Col 
                       className="recover-password-banner d-none d-lg-block" 
                       lg={8} xl={9}
                    >
                        
                        <div className="recover-password-footer mr-5 pr-5
                        ">  
                             {`© Scape ${year}. All rights reserved.`}
                        </div>
                    </Col>
                </Row>            
            </Container>
        </Main>
    );
};

export default RecoverPasswordPage;
